import React, { useCallback, useEffect, useMemo } from 'react';

import Assets from 'assets';
import useSideModal from 'lib/hook/util/useSideModal';
import CRButton from 'components/base/CRButton';
import dayjs from 'dayjs';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import CRStatus from 'components/base/CRStatus';
import { COMPLETE_STATUS_CONFIG } from 'components/domain/table/MonitoringTable/constant';
import { displayPadTime, MonitoringDetail } from 'lib';
import CRInputLabel from 'components/base/CRInputLabel';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import { MonitoringDetailsDTO, ResponseCode, SaveScheduleMonitoringDetailRequest } from 'types/api';
import { useMonitoringDetailItem } from 'lib/hook/react-query/query/monitoring';
import { ChargeTypeCds, ServiceKindCds } from 'pages/dashboard/SchedulePage/MonitoringDetailPage';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MonitoringDetailForm } from 'types/view/monitoring';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { Toast } from 'components/base/CRToast';
import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';
import {
	useSaveScheduleMonitoringDetail,
	useUpdateMonitoringDetailItemCompleteStatus,
} from 'lib/hook/react-query/mutation/monitoring';
import { endpoint } from 'lib/service/Api/endpoint';
import useDialog from 'lib/hook/util/useDialog';
import { MonitoringDetailItemsCompleteStatusDialog } from 'components/domain/dialog/MonitoringDetailItemsCompleteStatusDialog';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import { useQueryClient } from '@tanstack/react-query';
import * as S from './styles';

interface Props {
	item: MonitoringDetailsDTO;
	clearCurrentRow?: () => void;
}

function MonitoringSideModal({ item, clearCurrentRow }: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const hasPermission = useHasPermission(['센터장']);
	const queryClient = useQueryClient();

	const { data: monitoringDetailItem, refetch: detailRefetch } = useMonitoringDetailItem({
		centerId: item.centerId,
		serviceYm: item.serviceYm,
		recipientId: item.recipientId,
		employeeId: item.employeeId,
		serviceTypeCd: item.serviceTypeCd,
		startDate: dayjs(item.schedulePlanStartDt).format('YYYY-MM-DD'),
		endDate: dayjs(item.schedulePlanEndDt).format('YYYY-MM-DD'),
		startTime: `${displayPadTime(item.schedulePlanStartTime)}:00`,
		endTime: `${displayPadTime(item.schedulePlanEndTime)}:00`,
	});

	const { mutateAsync: cancelMonitoringSchedule } = useUpdateMonitoringDetailItemCompleteStatus(
		async (client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				await client.invalidateQueries([endpoint.getMonitoringDetailItem.key]);
				await client.invalidateQueries([endpoint.getMonitoringDetails.key]);
				Toast.success('일정 확정을 취소했습니다.');
			} else {
				Toast.error(`확정 취소에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.`);
			}
		},
	);

	const { reset, control, getValues, setValue, handleSubmit, trigger } =
		useForm<MonitoringDetailForm>({
			resolver: yupResolver(MonitoringDetail),
			mode: 'onSubmit',
			defaultValues: {
				data: item,
				time: {
					workStartTime: '',
					workEndTime: '',
				},
			},
		});

	const { mutateAsync: saveDetail } = useSaveScheduleMonitoringDetail();

	const isConfirmed = !!monitoringDetailItem?.scheduleMonitoringCompleteYn;

	const { hideSideModal } = useSideModal();

	const onClickClose = () => {
		clearCurrentRow?.();
		hideSideModal();
	};

	const renderUserInfo = () =>
		monitoringDetailItem?.serviceDt
			? `${dayjs(monitoringDetailItem?.serviceDt).format('YYYY년 M월 D일')}`
			: '';

	const renderCompleteStatus = useCallback(() => {
		if (monitoringDetailItem?.schedulePlanCompleteYn === undefined) return '-';
		return (
			<CRStatus options={COMPLETE_STATUS_CONFIG}>
				{monitoringDetailItem?.schedulePlanCompleteYn ? '완료' : '미완료'}
			</CRStatus>
		);
	}, [monitoringDetailItem]);

	const renderPlanTime = useCallback(() => {
		if (!monitoringDetailItem?.schedulePlanStartTime) return '-';
		return (
			<S.TextContainer>
				{monitoringDetailItem?.schedulePlanStartTime?.slice(0, 5)}~
				{monitoringDetailItem?.schedulePlanEndTime
					? monitoringDetailItem?.schedulePlanEndTime?.slice(0, 5)
					: ''}
			</S.TextContainer>
		);
	}, [monitoringDetailItem]);

	// RFID시간
	const renderRfidTime = useCallback(() => {
		if (!monitoringDetailItem?.rfidStartTime) return '-';
		return (
			<S.TextContainer>
				{monitoringDetailItem?.rfidStartTime?.slice(0, 5)}~
				{monitoringDetailItem?.rfidEndTime ? monitoringDetailItem?.rfidEndTime?.slice(0, 5) : ''}
			</S.TextContainer>
		);
	}, [monitoringDetailItem]);

	// 근무시간
	const renderRfidRecordingTime = useCallback(() => {
		if (!monitoringDetailItem?.workStartTime) return '-';
		return (
			<S.TextContainer>
				{monitoringDetailItem?.workStartTime?.slice(0, 5)}~
				{monitoringDetailItem?.workEndTime ? monitoringDetailItem?.workEndTime?.slice(0, 5) : ''}
			</S.TextContainer>
		);
	}, [monitoringDetailItem]);

	const renderRfidProcessState = useCallback(() => {
		if (!monitoringDetailItem?.rfidProcessState) return '-';
		return (
			<CRText
				text={monitoringDetailItem?.rfidProcessState}
				typography='label'
				color={monitoringDetailItem?.rfidProcessState === '미생성' ? 'primary60' : 'gray00'}
			/>
		);
	}, [monitoringDetailItem]);

	const isNotTaggedRecordingSheet = useMemo(
		() => monitoringDetailItem?.chargeTypeCd === ChargeTypeCds.기록지_미태그,
		[monitoringDetailItem],
	);

	const isActiveConfirmInput = useMemo(
		() =>
			[ChargeTypeCds.기록지_미태그, ChargeTypeCds.기록지_태그].includes(
				monitoringDetailItem?.chargeTypeCd as ChargeTypeCds,
			),
		[monitoringDetailItem],
	);

	// 인지요양이면서 태그 시간분배가 오류일떄
	const isCognitiveCareError =
		monitoringDetailItem?.tagTimeDistributionErrorYn === true &&
		monitoringDetailItem?.serviceKindCd === ServiceKindCds.인지요양;

	const isGeneralNursingOrFamilyNursingError =
		monitoringDetailItem?.tagTimeDistributionErrorYn === true &&
		[ServiceKindCds.방문요양, ServiceKindCds.가족요양].includes(
			monitoringDetailItem?.serviceKindCd as ServiceKindCds,
		);

	// 특이사항 확인
	const renderSpecialDescConfirm = (value?: boolean) => {
		if (value === undefined) return '-';
		return (
			<CRText
				text={value ? '작성 완료' : '미작성'}
				typography='label'
				color={value ? 'gray00' : 'primary60'}
			/>
		);
	};

	// 시간오류-수가일치여부
	const renderAmountMatchYn = () => {
		if (monitoringDetailItem?.serviceFeeMatchYn === undefined) return '-';
		if (monitoringDetailItem?.serviceFeeMatchYn)
			return <CRText text='일치' color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				justify='space-between'
				style={{
					width: '100%',
				}}>
				<CRText text='불일치' color='primary60' typography='label' />
				<RDTooltip
					side='top'
					content={
						<CRText
							color='gray100'
							typography='label'
							text={`RFID 시간을\n수가에 맞게 수정해 주십시오.`}
						/>
					}>
					<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
				</RDTooltip>
			</FlexContainer>
		);
	};

	// 시간오류-일정계획오류
	const renderServiceSchedulePlanTimeErrorYn = () => {
		if (monitoringDetailItem?.serviceSchedulePlanTimeErrorYn === undefined) return '-';
		if (!monitoringDetailItem?.serviceSchedulePlanTimeErrorYn)
			return <CRText text='정상' color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				justify='space-between'
				style={{
					width: '100%',
				}}>
				<CRText text='오류' color='primary60' typography='label' />
				<RDTooltip
					side='top'
					content={
						<CRText
							color='gray100'
							typography='label'
							text={
								monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문목욕
									? `일정 계획 총 시간을\n40분 또는 60분으로 수정해 주십시오.`
									: `일정 계획 시간을\n30분 이상 480분이하 범위 내\n30분 단위로 수정해 주십시오.`
							}
						/>
					}>
					<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
				</RDTooltip>
			</FlexContainer>
		);
	};

	// 시간오류-일반요양2시간간격 중복여부
	const renderCareIntervalWorkDuplYn = () => {
		if (monitoringDetailItem?.careIntervalWorkErrorYn === undefined) return '-';
		return (
			<CRText
				text={monitoringDetailItem?.careIntervalWorkErrorYn ? '오류' : '정상'}
				color={monitoringDetailItem?.careIntervalWorkErrorYn ? 'primary60' : 'gray00'}
				typography='label'
			/>
		);
	};

	// 시간오류-태그시간분배오류여부
	const renderTagTimeDistributionYn = () => {
		if (monitoringDetailItem?.tagTimeDistributionErrorYn === undefined) return '-';
		return (
			<CRText
				text={monitoringDetailItem?.tagTimeDistributionErrorYn ? '오류' : '정상'}
				color={monitoringDetailItem?.tagTimeDistributionErrorYn ? 'primary60' : 'gray00'}
				typography='label'
			/>
		);
	};

	// 시간오류-목욕 태그 미겹침
	const renderBathTagNonOverlapYn = () => {
		if (monitoringDetailItem?.bathTagNonOverlapYn === undefined) return '-';
		return (
			<CRText
				text={monitoringDetailItem?.bathTagNonOverlapYn ? '오류' : '정상'}
				color={monitoringDetailItem?.bathTagNonOverlapYn ? 'primary60' : 'gray00'}
				typography='label'
			/>
		);
	};

	// 태그정보-신체활동
	const renderPhysicalActivity = () => {
		const time = monitoringDetailItem?.physicalActivitySupptOfferHourCnt;
		const error = monitoringDetailItem?.physicalActivitySupptErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양은 제공이 불가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-인지자극활동
	const renderRecogStimulationActivity = () => {
		const time = monitoringDetailItem?.recogStimulationActivityHourCnt;
		const error = monitoringDetailItem?.recogStimulationActivityErrorYn;

		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;
		let cognitiveCareErrorMessage = '';

		const isFamilyEmployee = monitoringDetailItem?.employeeFamilyYn;
		if (isFamilyEmployee === true && time < 60) {
			cognitiveCareErrorMessage = '60분 이상을 제공해야합니다.';
		}
		if (isFamilyEmployee === false && time !== 60) {
			cognitiveCareErrorMessage = '60분을 제공해야합니다.';
		}

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && cognitiveCareErrorMessage && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text={cognitiveCareErrorMessage} />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
				{isGeneralNursingOrFamilyNursingError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양만 제공이 가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-일상생활 함께하기
	const renderDailylifeTogether = () => {
		const time = monitoringDetailItem?.dailylifeTogetherHourCnt;
		const error = monitoringDetailItem?.dailylifeTogetherErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;
		const isFamilyEmployee = monitoringDetailItem?.employeeFamilyYn;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && isFamilyEmployee === false && (time < 60 || time > 120) && (
					<RDTooltip
						side='top'
						content={<CRText color='gray100' typography='label' text='60분 이상이어야 합니다.' />}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
				{isGeneralNursingOrFamilyNursingError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양만 제공이 가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-인지행동변화관리
	const renderRecogBehaviorChange = () => {
		const time = monitoringDetailItem?.recogBehaviorChangeHourCnt;
		const error = monitoringDetailItem?.recogBehaviorChangeErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양은 제공이 불가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-정서지원
	const renderCommuhelpCompanionEncourage = () => {
		const time = monitoringDetailItem?.commuhelpCompanionEncourageHourCnt;
		const error = monitoringDetailItem?.commuhelpCompanionEncourageErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양은 제공이 불가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
				{isGeneralNursingOrFamilyNursingError && time > 60 && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='60분을 초과할 수 없습니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	// 태그정보-가사 및 일상생활
	const renderHworkDailylifeSupptOffer = () => {
		const time = monitoringDetailItem?.hworkDailylifeSupptOfferHourCnt;
		const error = monitoringDetailItem?.hworkDailylifeSupptOfferErrorYn;
		if (time === undefined) return '-';
		if (!error) return <CRText text={`${time}분`} color='gray00' typography='label' />;

		return (
			<FlexContainer
				align='center'
				style={{
					width: '100%',
					gap: '0.4rem',
				}}>
				<CRText text={`${time}분`} color='primary60' typography='label' />
				{isCognitiveCareError && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='인지요양은 제공이 불가능합니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
				{isGeneralNursingOrFamilyNursingError && time > 90 && (
					<RDTooltip
						side='top'
						content={
							<CRText color='gray100' typography='label' text='90분을 초과할 수 없습니다.' />
						}>
						<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
					</RDTooltip>
				)}
			</FlexContainer>
		);
	};

	const transformTimeString = (time = '') => {
		if (time.length <= 2) return time;
		return `${time.slice(0, 2)}:${time.slice(2, time.length)}`;
	};

	const handleChangeTime = (dayOfTheWeeks: DayOfTheWeek[], startTime = '', endTime = '') => {
		setValue('time.workStartTime', startTime?.replace(':', ''));
		setValue('time.workEndTime', endTime?.replace(':', ''));
	};

	const onSubmitFail = (errors: FieldErrors<MonitoringDetailForm>) => {
		Toast.error(Object.values(errors)?.[0]?.message || '입력폼을 확인해주세요');
	};

	const onSave = (data: MonitoringDetailForm) => {
		if (!monitoringDetailItem) return null;

		if (isNotTaggedRecordingSheet) {
			if (data.time?.workStartTime?.length !== 4 || data.time?.workEndTime?.length !== 4) {
				Toast.error('근무 시간은 필수입니다.');
				return null;
			}
		}

		const params: SaveScheduleMonitoringDetailRequest = {
			centerId: monitoringDetailItem.centerId,
			serviceYm: monitoringDetailItem.serviceYm,
			recipientId: monitoringDetailItem.recipientId,
			employeeId: monitoringDetailItem.employeeId,
			serviceTypeCd: monitoringDetailItem.serviceTypeCd,
			schedulePlanStartDate: monitoringDetailItem.schedulePlanStartDate,
			schedulePlanEndDate: monitoringDetailItem.schedulePlanEndDate,
		};

		if (data.scheduleMonitoringMemo) params.scheduleMonitoringMemo = data.scheduleMonitoringMemo;

		if (data.time?.workStartTime && data.time?.workEndTime) {
			params.workStartTime = `${displayPadTime(data.time.workStartTime)}:00`;
			params.workEndTime = `${displayPadTime(data.time.workEndTime)}:00`;
		}

		if (data.workConfirmYn?.length) params.workConfirmYn = data.workConfirmYn[0].value;
		if (data.otherCenterWorkDuplConfirmYn?.length)
			params.otherCenterWorkDuplConfirmYn = data.otherCenterWorkDuplConfirmYn[0].value;
		if (data.otherCorpWorkDuplConfirmYn?.length)
			params.otherCorpWorkDuplConfirmYn = data.otherCorpWorkDuplConfirmYn[0].value;
		if (data.careIntervalWorkDuplConfirmYn?.length)
			params.careIntervalWorkDuplConfirmYn = data.careIntervalWorkDuplConfirmYn[0].value;

		if (isNotTaggedRecordingSheet) {
			params.stateChangeRecordingSheetExpectYn = !!data.stateChangeRecordingSheetExpectYn?.length;
			params.specialHoursRecordingSheetExpectYn = !!data.specialHoursRecordingSheetExpectYn?.length;
			params.cognitiveProgramRecordingSheetExpectYn =
				!!data.cognitiveProgramRecordingSheetExpectYn?.length;
			params.biweeklyBathRecordingSheetExpectYn = !!data.biweeklyBathRecordingSheetExpectYn?.length;
		}
		return saveDetail(params);
	};

	const onSubmit = async (data: MonitoringDetailForm) => {
		const result = await onSave(data);
		if (result?.code === ResponseCode.SUCCESS) {
			await queryClient.invalidateQueries([endpoint.getMonitoringDetails.key]);
			await detailRefetch();
			Toast.success('정상적으로 저장하였습니다.');
		} else {
			Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
	};

	const handleClickComplete = () => {
		if (!monitoringDetailItem) return;
		if (monitoringDetailItem?.scheduleMonitoringCompleteYn) {
			// 확정 취소
			cancelMonitoringSchedule({
				centerId: monitoringDetailItem?.centerId,
				serviceYm: monitoringDetailItem?.serviceYm,
				recipientId: monitoringDetailItem?.recipientId,
				employeeId: monitoringDetailItem?.employeeId,
				serviceTypeCd: monitoringDetailItem?.serviceTypeCd,
				schedulePlanStartDate: monitoringDetailItem?.schedulePlanStartDate,
				schedulePlanEndDate: monitoringDetailItem?.schedulePlanEndDate,
				completeYn: false,
			});
		} else {
			// 확정
			showDialog(() => (
				<MonitoringDetailItemsCompleteStatusDialog
					item={monitoringDetailItem}
					onSave={onSave}
					formData={getValues()}
				/>
			));
		}
	};

	useEffect(() => {
		reset({
			workConfirmYn: isActiveConfirmInput
				? [
						{
							label: '',
							value: monitoringDetailItem?.workConfirmYn ?? false,
						},
				  ]
				: undefined,

			otherCenterWorkDuplConfirmYn: monitoringDetailItem?.otherCenterWorkDuplYn
				? [
						{
							label: '',
							value: monitoringDetailItem?.otherCenterWorkDuplConfirmYn ?? false,
						},
				  ]
				: undefined,

			otherCorpWorkDuplConfirmYn: monitoringDetailItem?.otherCorpWorkDuplYn
				? [
						{
							label: '',
							value: monitoringDetailItem?.otherCorpWorkDuplConfirmYn ?? false,
						},
				  ]
				: undefined,
			careIntervalWorkDuplConfirmYn: monitoringDetailItem?.careIntervalWorkErrorYn
				? [
						{
							label: '',
							value: monitoringDetailItem?.careIntervalWorkDuplConfirmYn ?? false,
						},
				  ]
				: undefined,
			time:
				monitoringDetailItem?.workStartTime && monitoringDetailItem.workEndTime
					? {
							workStartTime:
								monitoringDetailItem?.workStartTime?.slice(0, 5)?.replace(':', '') ?? '',
							workEndTime: monitoringDetailItem?.workEndTime?.slice(0, 5)?.replace(':', '') ?? '',
					  }
					: undefined,
			scheduleMonitoringMemo: monitoringDetailItem?.scheduleMonitoringMemo ?? '',
			stateChangeRecordingSheetExpectYn: monitoringDetailItem?.stateChangeRecordingSheetExpectYn
				? [
						{
							label: '',
							value: true,
						},
				  ]
				: undefined,
			specialHoursRecordingSheetExpectYn: monitoringDetailItem?.specialHoursRecordingSheetExpectYn
				? [
						{
							label: '',
							value: true,
						},
				  ]
				: undefined,
			cognitiveProgramRecordingSheetExpectYn:
				monitoringDetailItem?.cognitiveProgramRecordingSheetExpectYn
					? [
							{
								label: '',
								value: true,
							},
					  ]
					: undefined,
			biweeklyBathRecordingSheetExpectYn: monitoringDetailItem?.biweeklyBathRecordingSheetExpectYn
				? [
						{
							label: '',
							value: true,
						},
				  ]
				: undefined,
		});
	}, [monitoringDetailItem, isActiveConfirmInput]);
	return (
		<S.Container onSubmit={handleSubmit(onSubmit, onSubmitFail)}>
			<S.Header>
				<CRText typography='h4' text={renderUserInfo()} color='gray00' />
				<S.CloseIcon src={Assets.icon.close} alt='닫기' onClick={onClickClose} />
			</S.Header>
			<S.Content>
				<S.ScheduleDetail>
					<S.AccordionContentItem>
						<S.DetailLabel>상태</S.DetailLabel>
						<S.DetailValue>{renderCompleteStatus()}</S.DetailValue>
					</S.AccordionContentItem>
					{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문목욕 && (
						<S.AccordionContentItem>
							<S.DetailLabel>직원</S.DetailLabel>
							<S.DetailValue>{monitoringDetailItem?.employeeNm ?? '-'}</S.DetailValue>
						</S.AccordionContentItem>
					)}
					<S.AccordionContentItem>
						<S.DetailLabel>일정 계획 시간</S.DetailLabel>
						<S.DetailValue>{renderPlanTime()}</S.DetailValue>
					</S.AccordionContentItem>
					<S.AccordionContentItem>
						<S.DetailLabel>청구 유형</S.DetailLabel>
						<S.DetailValue>{monitoringDetailItem?.chargeTypeNm ?? '-'}</S.DetailValue>
					</S.AccordionContentItem>
					<S.AccordionContentItem>
						<S.DetailLabel>RFID 시간</S.DetailLabel>
						<S.DetailValue>{renderRfidTime()}</S.DetailValue>
					</S.AccordionContentItem>
					<S.AccordionContentItem>
						<S.DetailLabel>
							<FlexContainer>
								<CRText text='근무 시간' color='gray60' typography='label' />
								<RDTooltip
									side='top'
									content={
										<CRText
											color='gray100'
											typography='label'
											text='일정계획시간과 근무시간은 동일해야합니다.'
										/>
									}>
									<S.QuestionMark src={Assets.icon.help} alt='에러 설명' />
								</RDTooltip>
							</FlexContainer>
						</S.DetailLabel>
						<S.DetailValue>{renderRfidRecordingTime()}</S.DetailValue>
					</S.AccordionContentItem>
					<S.AccordionContentItem>
						<S.DetailLabel>처리 상태</S.DetailLabel>
						<S.DetailValue>{renderRfidProcessState()}</S.DetailValue>
					</S.AccordionContentItem>
					<S.AccordionContentItem>
						<S.DetailLabel>특이사항</S.DetailLabel>
						<S.DetailValue>{monitoringDetailItem?.rfidSpecialDesc ?? '-'}</S.DetailValue>
					</S.AccordionContentItem>
				</S.ScheduleDetail>
				<Controller
					render={({ field: { onChange, value }, formState: { errors } }) => (
						<CRInputLabel label='메모'>
							<CRInput.TextArea
								disabled={isConfirmed}
								onChange={onChange}
								value={value}
								height='13.1rem'
								numberOfLines={3.5}
								placeholder='메모 입력'
								fixedHeight
							/>
						</CRInputLabel>
					)}
					name='scheduleMonitoringMemo'
					control={control}
				/>
				{isNotTaggedRecordingSheet && (
					<Controller
						render={({ field: { value }, formState: { errors } }) => (
							<CRInputLabel label='근무 시간' isRequired>
								<CRInput.TimePicker
									disabled={isConfirmed}
									type='time'
									start={transformTimeString(value?.workStartTime)}
									end={transformTimeString(value?.workEndTime)}
									onChange={handleChangeTime}
								/>
							</CRInputLabel>
						)}
						name='time'
						control={control}
					/>
				)}
				<Controller
					render={({ field: { onChange, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='근무 확인' isRequired>
							<CRCheckBoxGroup
								disabled={isConfirmed || !isActiveConfirmInput}
								checkType='single'
								onChange={onChange}
								value={value}
								appearanceType='button'
								type='radio'
								gap={0.8}
								options={[
									{
										label: '미확인',
										value: false,
									},
									{
										label: '확인 완료',
										value: true,
									},
								]}
							/>
						</CRInputLabel>
					)}
					name='workConfirmYn'
					control={control}
				/>
				<S.CheckGroupContainer>
					<S.ContentTitle>
						<CRText text='특이사항 확인' color='gray10' typography='bodyB' />
					</S.ContentTitle>
					<S.Table>
						<S.TableRow>
							<S.TableLabelColumn>주1회 상태변화기록</S.TableLabelColumn>
							<S.TableValueColumn
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}>
								{renderSpecialDescConfirm(monitoringDetailItem?.stateChangeRecordWriteYn)}
								{monitoringDetailItem?.stateChangeRecordingSheetExpectNeedYn && (
									<Controller
										render={({
											field: { onChange, onBlur, value, ref },
											formState: { errors },
										}) => (
											<CRCheckBoxGroup
												disabled={isConfirmed}
												checkType='multiple'
												onChange={onChange}
												value={value}
												type='checkbox'
												gap={0.8}
												options={[
													{
														label: '기록지 반영 예정',
														value: true,
													},
												]}
											/>
										)}
										name='stateChangeRecordingSheetExpectYn'
										control={control}
									/>
								)}
							</S.TableValueColumn>
						</S.TableRow>
						{monitoringDetailItem?.serviceKindCd === ServiceKindCds.방문요양 && (
							<S.TableRow>
								<S.TableLabelColumn>조조/야간/공휴일 이용 사유</S.TableLabelColumn>
								<S.TableValueColumn
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}>
									{renderSpecialDescConfirm(monitoringDetailItem?.specialHoursWriteYn)}
									{isNotTaggedRecordingSheet &&
										monitoringDetailItem?.specialHoursWriteYn === false && (
											<Controller
												render={({
													field: { onChange, onBlur, value, ref },
													formState: { errors },
												}) => (
													<CRCheckBoxGroup
														disabled={isConfirmed}
														checkType='multiple'
														onChange={onChange}
														value={value}
														type='checkbox'
														gap={0.8}
														options={[
															{
																label: '기록지 반영 예정',
																value: true,
															},
														]}
													/>
												)}
												name='specialHoursRecordingSheetExpectYn'
												control={control}
											/>
										)}
								</S.TableValueColumn>
							</S.TableRow>
						)}
						{monitoringDetailItem?.serviceKindCd === ServiceKindCds.인지요양 && (
							<S.TableRow>
								<S.TableLabelColumn>인지활동 특이사항</S.TableLabelColumn>
								<S.TableValueColumn
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}>
									{renderSpecialDescConfirm(monitoringDetailItem?.cognitiveProgramWriteYn)}
									{isNotTaggedRecordingSheet &&
										monitoringDetailItem?.cognitiveProgramWriteYn === false && (
											<Controller
												render={({
													field: { onChange, onBlur, value, ref },
													formState: { errors },
												}) => (
													<CRCheckBoxGroup
														disabled={isConfirmed}
														checkType='multiple'
														onChange={onChange}
														value={value}
														type='checkbox'
														gap={0.8}
														options={[
															{
																label: '기록지 반영 예정',
																value: true,
															},
														]}
													/>
												)}
												name='cognitiveProgramRecordingSheetExpectYn'
												control={control}
											/>
										)}
								</S.TableValueColumn>
							</S.TableRow>
						)}
						{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문목욕 && (
							<S.TableRow>
								<S.TableLabelColumn>목욕 주1회 초과 이용 사유</S.TableLabelColumn>
								<S.TableValueColumn
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}>
									{renderSpecialDescConfirm(monitoringDetailItem?.biweeklyBathWriteYn)}
									{monitoringDetailItem?.biweeklyBathRecordingSheetExpectNeedYn && (
										<Controller
											render={({
												field: { onChange, onBlur, value, ref },
												formState: { errors },
											}) => (
												<CRCheckBoxGroup
													disabled={isConfirmed}
													checkType='multiple'
													onChange={onChange}
													value={value}
													type='checkbox'
													gap={0.8}
													options={[
														{
															label: '기록지 반영 예정',
															value: true,
														},
													]}
												/>
											)}
											name='biweeklyBathRecordingSheetExpectYn'
											control={control}
										/>
									)}
								</S.TableValueColumn>
							</S.TableRow>
						)}
					</S.Table>
				</S.CheckGroupContainer>

				<S.CheckGroupContainer>
					<S.ContentTitle>
						<CRText text='시간 오류 확인' color='gray10' typography='bodyB' />
					</S.ContentTitle>
					<S.Table>
						<S.TableRow>
							<S.TableLabelColumn>수가 일치 여부</S.TableLabelColumn>
							<S.TableValueColumn>{renderAmountMatchYn()}</S.TableValueColumn>
						</S.TableRow>
						<S.TableRow>
							<S.TableLabelColumn>일정 계획 오류</S.TableLabelColumn>
							<S.TableValueColumn>{renderServiceSchedulePlanTimeErrorYn()}</S.TableValueColumn>
						</S.TableRow>
						{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문요양 && (
							<>
								<S.TableRow>
									<S.TableLabelColumn>일반요양 2시간 간격</S.TableLabelColumn>
									<S.TableValueColumn>{renderCareIntervalWorkDuplYn()}</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn>태그 시간 분배</S.TableLabelColumn>
									<S.TableValueColumn>{renderTagTimeDistributionYn()}</S.TableValueColumn>
								</S.TableRow>
							</>
						)}
						{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문목욕 && (
							<S.TableRow>
								<S.TableLabelColumn>태그 미겹침</S.TableLabelColumn>
								<S.TableValueColumn>{renderBathTagNonOverlapYn()}</S.TableValueColumn>
							</S.TableRow>
						)}
					</S.Table>
					{monitoringDetailItem?.serviceTypeCd === ServiceTypeCd.방문요양 && (
						<S.TagInfoContainer>
							<S.SubTitle>태그 정보</S.SubTitle>
							<S.ScheduleDetail>
								<S.AccordionContentItem>
									<S.DetailLabel>신체활동</S.DetailLabel>
									<S.DetailValue>{renderPhysicalActivity()}</S.DetailValue>
								</S.AccordionContentItem>
								<S.AccordionContentItem>
									<S.DetailLabel>인지자극활동</S.DetailLabel>
									<S.DetailValue>{renderRecogStimulationActivity()}</S.DetailValue>
								</S.AccordionContentItem>
								<S.AccordionContentItem>
									<S.DetailLabel>일상생활 함께하기</S.DetailLabel>
									<S.DetailValue>{renderDailylifeTogether()}</S.DetailValue>
								</S.AccordionContentItem>
								<S.AccordionContentItem>
									<S.DetailLabel>인지행동 변화관리</S.DetailLabel>
									<S.DetailValue>{renderRecogBehaviorChange()}</S.DetailValue>
								</S.AccordionContentItem>
								<S.AccordionContentItem>
									<S.DetailLabel>정서지원</S.DetailLabel>
									<S.DetailValue>{renderCommuhelpCompanionEncourage()}</S.DetailValue>
								</S.AccordionContentItem>
								<S.AccordionContentItem>
									<S.DetailLabel>가사 및 일상생활</S.DetailLabel>
									<S.DetailValue>{renderHworkDailylifeSupptOffer()}</S.DetailValue>
								</S.AccordionContentItem>
							</S.ScheduleDetail>
						</S.TagInfoContainer>
					)}
				</S.CheckGroupContainer>
				<S.CheckGroupContainer>
					<S.ContentTitle>
						<CRText text='일정 중복 확인' color='gray10' typography='bodyB' />
					</S.ContentTitle>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='타센터 중복'>
								<CRCheckBoxGroup
									disabled={isConfirmed || !monitoringDetailItem?.otherCenterWorkDuplYn}
									checkType='single'
									onChange={onChange}
									value={value}
									appearanceType='button'
									type='radio'
									gap={0.8}
									options={[
										{
											label: '미확인',
											value: false,
										},
										{
											label: '확인 완료',
											value: true,
										},
									]}
								/>
							</CRInputLabel>
						)}
						name='otherCenterWorkDuplConfirmYn'
						control={control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='타직장 중복'>
								<CRCheckBoxGroup
									disabled={isConfirmed || !monitoringDetailItem?.otherCorpWorkDuplYn}
									checkType='single'
									onChange={onChange}
									value={value}
									appearanceType='button'
									type='radio'
									gap={0.8}
									options={[
										{
											label: '미확인',
											value: false,
										},
										{
											label: '확인 완료',
											value: true,
										},
									]}
								/>
							</CRInputLabel>
						)}
						name='otherCorpWorkDuplConfirmYn'
						control={control}
					/>
				</S.CheckGroupContainer>
			</S.Content>
			<S.ButtonContainer>
				<CRButton.Default type='text' palette='gray' onClick={() => hideSideModal()}>
					취소
				</CRButton.Default>
				<CRButton.Default
					disabled={monitoringDetailItem?.scheduleMonitoringCompleteYn}
					buttonType='submit'
					palette={hasPermission ? 'gray' : 'primary'}
					type={hasPermission ? 'tonal' : 'filled'}>
					저장
				</CRButton.Default>
				{hasPermission && (
					<CRButton.Default onClick={handleClickComplete}>
						{monitoringDetailItem?.scheduleMonitoringCompleteYn ? '확정 취소' : '확정'}
					</CRButton.Default>
				)}
			</S.ButtonContainer>
		</S.Container>
	);
}

export default React.memo(MonitoringSideModal);
