import React, { ChangeEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { PageInfo } from 'types/view/base';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import { useCenterManagers, useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { Filter } from 'types/view/filter';
import CRTableHeader from 'components/base/CRTableHeader';
import CRSpinner from 'components/base/CRSpinner';
import { CheckOption } from 'components/base/Selections/type';
import CRButton from 'components/base/CRButton';

import { commonCodeAdapter } from 'lib/adapter/common';
import { useInsurance } from 'lib/hook/react-query/query/insurance';
import InsuranceTable from 'components/domain/table/InsuranceTable';
import { useUpdateInsuranceRequestState } from 'lib/hook/react-query/mutation/insurance';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import useDialog from 'lib/hook/util/useDialog';
import { Toast } from 'components/base/CRToast';
import { ResponseCode } from 'types/api';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import { InsuranceDTO } from 'types/api/insurance';
import CRTable from 'components/base/CRTable';
import { InsuranceState } from 'pages/dashboard/InsuranceDetailPage';
import usePageFilter from 'lib/hook/util/usePageFilter';
import * as S from './styles';

interface Props {
	hasPermission?: boolean;
}

export default function InsuranceTab({ hasPermission = false }: Props) {
	const [keyword, setKeyword] = useState('');
	const [checkIds, setCheckIds] = useState<string[]>([]);
	const {
		filters: currentFilter,
		setFilters: setCurrentFilter,
		pageInfo,
		setPageInfo,
	} = usePageFilter({});

	const navigate = useNavigate();
	const { showDialog, hideDialog } = useDialog();
	const [searchValue, setSearchValue] = useState('');
	const {
		data: commonCodes = {
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN161', 'CMN073'] }, commonCodeAdapter);
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const stateFilter = useMemo(
		() =>
			({
				key: 'stateFilter',
				type: 'single',
				placeholder: '상태',
				options: commonCodes?.CMN161 || [],
			} as Filter<CheckOption>),
		[commonCodes?.CMN161],
	);

	const typeFilter = useMemo(
		() =>
			({
				key: 'typeFilter',
				type: 'multi',
				placeholder: '득실 타입',
				options: commonCodes?.CMN073 || [],
			} as Filter<CheckOption>),
		[commonCodes?.CMN073],
	);

	const currentRequestState = currentFilter?.stateFilter?.[0].value || '';

	const {
		data: insuranceData,
		isLoading: insuranceDataLoading,
		refetch,
	} = useInsurance({
		centerId: myAccountInfo?.centerId,
		params: {
			centerId: myAccountInfo?.centerId,
			page: pageInfo.page,
			size: pageInfo.size,
			insuranceRequestStateCds: currentFilter?.stateFilter?.map((item) => item?.value),
			insuranceRequestTypeCds: currentFilter?.typeFilter?.map((item) => item?.value),
			requesterIds: currentFilter?.managerFilter?.map((item) => item?.value),
			keyword,
		},
		// centerId: myAccountInfo?.centerId,
	});

	const { mutate: changeInsuranceStateToRead } = useUpdateInsuranceRequestState(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 상태를 변경 하였습니다.');
				refetch();
				hideDialog();
			} else {
				Toast.error('상태 변경에 실패했습니다. 잠시후 다시 시도해주세요.');
			}
		},
	);

	const insuranceListData = useMemo(() => insuranceData?.content ?? [], [insuranceData]);

	const handleSearchValue = (value: string) => setSearchValue(value);
	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};
	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleClickChangeInsuranceState = () => {
		const nextStateText = currentRequestState === InsuranceState.대기 ? '진행중으' : '완료';
		showDialog(() => (
			<DefaultDialog
				hideCloseButton
				title={`${nextStateText}로 상태를 변경 하시겠습니까?`}
				content={`총 ${checkIds.length}명의 상태를 ${nextStateText}로 변경을 진행합니다.`}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '변경',
					successCallback: () => {
						changeInsuranceStateToRead({
							centerId: myAccountInfo?.centerId,
							currentState: currentRequestState,
							insuranceRequestIds: checkIds?.map((item) => Number(item)),
						});
					},
				}}
				hideDialog={hideDialog}
			/>
		));
	};

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options:
					centerManagers?.map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})) ?? [],
				placeholder: '사회복지사',
			} as Filter<CheckOption>),
		[centerManagers],
	);

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>4대보험 정보가 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>정보가 존재하지 않습니다.</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	const handleRefresh = () => {
		refetch();
	};

	const handleToggleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
		if (hasPermission) {
			if (e.target.checked) {
				setCheckIds(
					insuranceListData
						?.filter((item) =>
							[InsuranceState.대기, InsuranceState.진행중].includes(
								item.insuranceRequestState.id as InsuranceState,
							),
						)
						?.map((item) => String(item.insuranceRequestId)),
				);
			} else {
				setCheckIds([]);
			}
		}
	};

	const handleChangCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
		const matchedId = checkIds.includes(e.target.id);
		if (matchedId) {
			setCheckIds(checkIds.filter((checkedId) => checkedId !== e.target.id));
		} else {
			setCheckIds([...checkIds, e.target.id]);
		}
	};

	const handleClickRow = (item: InsuranceDTO) => {
		navigate(
			`${RouterPath.insurance().tab.key}/${item?.employeeId}/detail/${item.insuranceRequestId}`,
		);
	};

	const renderSendButton = () => {
		if (
			!hasPermission ||
			![InsuranceState.대기, InsuranceState.진행중].includes(currentRequestState)
		) {
			return undefined;
		}

		return (
			<S.SendButtonContainer>
				<CRButton.Default
					onClick={handleClickChangeInsuranceState}
					disabled={!checkIds.length}
					palette='gray'
					size='small'
					style={{
						padding: '0 1.2rem',
						borderRadius: '0.4rem',
					}}>
					{currentRequestState === InsuranceState.대기 ? '진행중으' : '완료'}로 변경
				</CRButton.Default>
				{checkIds.length > 0 && <S.SendButtonText>({checkIds.length}개 선택)</S.SendButtonText>}
				<S.Divider />
			</S.SendButtonContainer>
		);
	};

	useEffect(() => {
		if (insuranceData?.totalElements !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: insuranceData?.totalElements
					? Math.ceil(insuranceData.totalElements / prev.size)
					: 0,
			}));
		}
	}, [insuranceData?.totalElements]);

	useEffect(() => {
		setCheckIds([]);
	}, [currentFilter]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	useEffect(() => {
		setCheckIds([]);
	}, [currentRequestState]);

	return (
		<CRTable.BackBlind>
			<S.TableContainer>
				<CRTableHeader
					onChangePageInfo={handleChangePageInfo}
					pageInfo={pageInfo}
					currentFilter={currentFilter}
					stickyMode
					filters={[stateFilter, typeFilter, managerFilter]}
					searchValue={searchValue}
					placeholder='직원명으로 검색'
					onChangeSearchValue={handleSearchValue}
					setCurrentFilter={setCurrentFilter}
					onSearch={handleSearch}
					showRefresh
					showViewCount
					onRefresh={handleRefresh}
					renderCustomFilter={renderSendButton()}
				/>
				{insuranceDataLoading ? (
					<CRSpinner />
				) : insuranceListData && insuranceListData.length > 0 ? (
					<S.TableScrollContainer>
						<InsuranceTable
							currentRequestState={currentRequestState}
							checkIds={checkIds}
							hasPermission={hasPermission}
							handleToggleCheckAll={handleToggleCheckAll}
							handleChangCheckBox={handleChangCheckBox}
							items={insuranceListData}
							onClickRow={handleClickRow}
						/>
					</S.TableScrollContainer>
				) : (
					renderEmpty
				)}
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}
