// 모니터링

import { BaseResponse } from './base';
import { PageResponse } from './common';

export type MonitoringStatus = '처리완료' | '처리중' | '미처리';

export type MonitoringDetailStatus = '검수 필요' | '검수 완료' | '저장완료';

export enum MonitoringUploadTypeCds {
	일정모니터링_동기화 = 'CMN107.SYNC_SCHEDULE_MONITORING',
	공단_일정계획_동기화 = 'CMN107.SYNC_PCORP_SCHEDULE',
	공단_태그_동기화 = 'CMN107.SYNC_PCORP_TAG',
	공단_기타_RFID_동기화 = 'CMN107.SYNC_PCORP_ETC_RFID',
}

export type MonitoringDetailContentDTO = {
	id: string;
	endDate: string;
	type: string;
	estimatedTime: string;
	measuredTime: string;
	record: string;
	status: MonitoringDetailStatus;
};

export type MonitoringContentDTO = {
	id: string;
	recipient: string;
	EmployeeName: string;
	serviceType: string;
	taskCount: number;
	status: MonitoringStatus;
	contractType: string;
	servicePeriod: string;
	items: MonitoringDetailContentDTO[];
	startDate: string;
	endDate: string;
};

export interface MonitoringSchedulesRequest {
	centerId?: number;
	serviceYm: string;
	state?: boolean | null;
	confirmYn: boolean;
	serviceKindCds?: string[];
	serviceTypeCds?: string[];
	workConfirmYn?: boolean | null;
	workDuplConfirmYn?: boolean | null;
	managerIds?: number[];
	page: number;
	size: number;
	keyword?: string;
}

export interface MonitoringDTO {
	centerId: number;
	centerNm: string;
	centerAliasNm: string;
	serviceYm: string;
	state: boolean;
	confirmYn: boolean;
	recipientId: number;
	recipientNm: string;
	recipientBirthDt: string;
	longTermNo: string;
	serviceTypeCd: string;
	serviceTypeNm: string;
	serviceKindCd: string;
	serviceKindNm: string;
	employeeId1: number;
	employeeNm1: string;
	employeeBirthDt1: string;
	employeeFamilyYn1: string;
	employeeId2: number;
	employeeNm2: string;
	employeeBirthDt2: string;
	employeeFamilyYn2: string;
	socialWorkerId: number;
	socialWorkerNm: string;
	workConfirmYn: boolean;
	workDuplConfirmYn: boolean;
	syncCompleteDate: string;
	employeeDataCreation1: string;
	employeeDataCreation2: string;
	scheduleMonitoringCompleteYn: boolean;
}

export type MonitoringResponse = BaseResponse<PageResponse<MonitoringDTO[]>>;

export interface MonitoringDetailsRequest {
	centerId: string;
	serviceYm: string;
	recipientId: string;
	employeeId1: string;
	employeeId2?: string;
	serviceTypeCd: string;
}

export interface MonitoringDetailsDTO {
	scheduleMonitoringId: number;
	centerId: number;
	serviceYm: string;
	schedulePlanCompleteYn: boolean;
	recipientId: number;
	recipientNm: string;
	recipientBirthDt: string;
	longTermNo: string;
	employeeId: number;
	employeeNm: string;
	employeeBirthDt: string;
	serviceTypeCd: string;
	serviceTypeNm: string;
	serviceKindCd: string;
	serviceKindNm: string;
	chargeTypeCd?: string;
	chargeTypeNm?: string;
	serviceDt: string;
	schedulePlanStartTime: string;
	schedulePlanEndTime: string;
	rfidStartTime: string;
	rfidEndTime: string;
	workConfirmYn: boolean;
	serviceFeeMatchYn?: boolean;
	tagTimeDistributionYn?: boolean;
	scheduleMonitoringMemo: string;
	specialDescCheckNeeds: string[];
	workDuplicationCheckNeeds: string[];
	socialWorkerId: number;
	socialWorkerNm: string;
	syncCompleteDate: string;
	timeErrCheckNeeds: string[];
	rfidRecordingStartTime: string;
	rfidRecordingEndTime: string;
	rfidProcessState: string;
	scheduleMonitoringCompleteYn: boolean;
	workStartTime: string;
	workEndTime: string;
	schedulePlanStartDt: string;
	schedulePlanEndDt: string;
}

export type MonitoringDetailsResponse = BaseResponse<MonitoringDetailsDTO[]>;

export type MonitoringDetailItemDTO = {
	id: string;
	date: Date;
	wrokStartTime: string;
	workEndTime: string;
};

export interface SyncPCorpRequest {
	centerId: number;
	serviceYm: string;
	syncTypeCds: MonitoringUploadTypeCds[];
}

export type SyncPCorpResponse = BaseResponse<string>;

export interface MonitoringSyncDateRequest {
	centerId?: number;
	serviceYm: string;
}

export interface MonitoringSyncDateDTO {
	centerId: number;
	centerNm: string;
	uploadTargetYm: string;
	uploadTypeCd: string;
	uploadTypeNm: string;
	uploadTotalCnt: number;
	uploadCompleteCnt: number;
	uploadRemainCnt: number;
	uploadStartDate: string;
	uploadEndDate: string;
	uploadStateCd: string;
	uploadStateNm: string;
	uploadResultDesc: string;
	createdId: number;
	createdNm: string;
	uploadHistId: number;
}

export type MonitoringSyncDateResponse = BaseResponse<MonitoringSyncDateDTO[]>;

export interface SyncMonitoringDataRequest {
	centerId: number;
	serviceYm: string;
}

export interface SyncMonitoringDataDTO {
	centerId: number;
	serviceYm: string;
	scheduleMonitoringDataSyncRequestDate: string;
}

export type SyncMonitoringDataResponse = BaseResponse<SyncMonitoringDataDTO>;

export interface SyncMonitoringIndividualRequest {
	centerId: number;
	serviceYm: string;
	recipientId: number;
	employeeId1: number;
	employeeId2?: number;
	serviceTypeCd: string;
}

export interface SyncMonitoringIndividualDTO {
	centerId: number;
	serviceYm: string;
	scheduleMonitoringDataSyncRequestDate: string;
}

export type SyncMonitoringIndividualResponse = BaseResponse<SyncMonitoringIndividualDTO>;

export interface MonitoringSyncHistoryRequest {
	centerId?: number;
	serviceYm: string;
	syncTypeCds: string[];
}

export interface MonitoringSyncHistoryDTO {
	centerId: number;
	centerNm: string;
	uploadTargetYm: string;
	uploadTypeCd: string;
	uploadTypeNm: string;
	uploadTotalCnt: number;
	uploadCompleteCnt: number;
	uploadRemainCnt: number;
	uploadStartDate: string;
	uploadEndDate: string;
	uploadStateCd: string;
	uploadStateNm: string;
	uploadResultDesc: string;
	createdId: number;
	createdNm: string;
	uploadHistId: number;
}

export type MonitoringSyncHistoryResponse = BaseResponse<MonitoringSyncHistoryDTO[]>;

export interface MonotoringDetailItemRequest {
	centerId: number;
	serviceYm: string;
	recipientId: number;
	employeeId: number;
	serviceTypeCd: string;
	startDate: string;
	endDate: string;
	startTime: string;
	endTime: string;
}

export interface MonotoringDetailItemDTO {
	scheduleMonitoringId: number;
	centerId: number;
	serviceYm: string;
	schedulePlanCompleteYn: boolean;
	recipientId: number;
	recipientNm: string;
	recipientBirthDt: string;
	longTermNo: string;
	employeeId: number;
	employeeNm: string;
	employeeBirthDt: string;
	serviceTypeCd: string;
	serviceTypeNm: string;
	serviceKindCd: string;
	serviceKindNm: string;
	serviceDt: string;
	schedulePlanStartDate: string;
	schedulePlanEndDate: string;
	rfidStartDate: string;
	rfidEndDate: string;
	rfidSpecialDesc: string;
	scheduleMonitoringMemo: string;
	workStartDate: string;
	workEndDate: string;
	workConfirmYn: boolean;
	serviceFeeMatchYn: boolean;
	physicalActivitySupptOfferHourCnt: number;
	physicalActivitySupptErrorYn: boolean;
	recogStimulationActivityHourCnt: number;
	recogStimulationActivityErrorYn: boolean;
	dailylifeTogetherHourCnt: number;
	dailylifeTogetherErrorYn: boolean;
	recogBehaviorChangeHourCnt: number;
	recogBehaviorChangeErrorYn: boolean;
	commuhelpCompanionEncourageHourCnt: number;
	commuhelpCompanionEncourageErrorYn: boolean;
	hworkDailylifeSupptOfferHourCnt: number;
	hworkDailylifeSupptOfferErrorYn: boolean;
	otherCenterWorkDuplYn: boolean;
	otherCorpWorkDuplYn: boolean;
	socialWorkerId: number;
	socialWorkerNm: string;
	syncCompleteDate: string;
	schedulePlanStartTime: string;
	schedulePlanEndTime: string;
	rfidStartTime: string;
	rfidEndTime: string;
	workStartTime: string;
	workEndTime: string;
	chargeTypeCd: string;
	chargeTypeNm: string;
	employeeFamilyYn: boolean;
	otherCenterWorkDuplConfirmYn: boolean;
	otherCorpWorkDuplConfirmYn: boolean;
	careIntervalWorkDuplConfirmYn: boolean;
	bathTagNonOverlapYn?: boolean;
	bathAdmitTimeOverYn?: boolean;
	stateChangeRecordWriteYn?: boolean;
	specialHoursWriteYn?: boolean;
	cognitiveProgramWriteYn?: boolean;
	biweeklyBathWriteYn?: boolean;
	rfidRecordingStartDate: string;
	rfidRecordingEndDate: string;
	stateChangeRecordingSheetExpectNeedYn: boolean;
	biweeklyBathRecordingSheetExpectNeedYn: boolean;
	stateChangeRecordingSheetExpectYn?: boolean;
	specialHoursRecordingSheetExpectYn?: boolean;
	cognitiveProgramRecordingSheetExpectYn?: boolean;
	biweeklyBathRecordingSheetExpectYn?: boolean;
	scheduleMonitoringCompleteYn: boolean;
	serviceSchedulePlanTimeErrorYn?: boolean;
	tagTimeDistributionErrorYn?: boolean;
	careIntervalWorkErrorYn?: boolean;
	rfidProcessState?: string;
}

export type MonotoringDetailItemResponse = BaseResponse<MonotoringDetailItemDTO>;

export interface SaveMonitoringDetail {
	centerId: number;
	serviceYm: string;
	recipientId: number;
	employeeId: number;
	serviceKindCd: string;
	schedulePlanStartDate: string;
	schedulePlanEndDate: string;
	scheduleMonitoringMemo: string;
	workStartTime?: string;
	workEndTime?: string;
	workConfirmYn?: boolean;
	otherCenterWorkDuplConfirmYn?: boolean;
	otherCorpWorkDuplConfirmYn?: boolean;
	careIntervalWorkDuplConfirmYn?: boolean;
}

export interface SaveScheduleMonitoringDetailRequest {
	centerId: number;
	serviceYm: string;
	recipientId: number;
	employeeId: number;
	serviceTypeCd: string;
	schedulePlanStartDate: string;
	schedulePlanEndDate: string;
	scheduleMonitoringMemo?: string;
	workStartTime?: string;
	workEndTime?: string;
	workConfirmYn?: boolean;
	otherCenterWorkDuplConfirmYn?: boolean;
	otherCorpWorkDuplConfirmYn?: boolean;
	careIntervalWorkDuplConfirmYn?: boolean;
	stateChangeRecordingSheetExpectYn?: boolean;
	specialHoursRecordingSheetExpectYn?: boolean;
	cognitiveProgramRecordingSheetExpectYn?: boolean;
	biweeklyBathRecordingSheetExpectYn?: boolean;
	scheduleMonitoringCompleteYn?: boolean;
}

export interface SaveScheduleMonitoringDetailDTO {
	scheduleMonitoringId: number;
	centerId: number;
	serviceYm: string;
	recipientId: number;
	recipientNm: string;
	recipientBirthDt: string;
	longTermNo: string;
	employeeId: number;
	employeeNm: string;
	employeeBirthDt: string;
	employeeFamilyYn: boolean;
	serviceTypeCd: string;
	serviceTypeNm: string;
	serviceKindCd: string;
	serviceKindNm: string;
	schedulePlanCompleteYn: boolean;
	chargeTypeCd: string;
	chargeTypeNm: string;
	serviceDt: string;
	schedulePlanStartDate: string;
	schedulePlanEndDate: string;
	rfidStartDate: string;
	rfidEndDate: string;
	rfidSpecialDesc: string;
	scheduleMonitoringMemo: string;
	workStartDate: string;
	workEndDate: string;
	workConfirmYn: boolean;
	serviceFeeMatchYn: boolean;
	physicalActivitySupptOfferHourCnt: number;
	physicalActivitySupptErrorYn: boolean;
	recogStimulationActivityHourCnt: number;
	recogStimulationActivityErrorYn: boolean;
	dailylifeTogetherHourCnt: number;
	dailylifeTogetherErrorYn: boolean;
	recogBehaviorChangeHourCnt: number;
	recogBehaviorChangeErrorYn: boolean;
	commuhelpCompanionEncourageHourCnt: number;
	commuhelpCompanionEncourageErrorYn: boolean;
	hworkDailylifeSupptOfferHourCnt: number;
	hworkDailylifeSupptOfferErrorYn: boolean;
	otherCenterWorkDuplConfirmYn: boolean;
	otherCorpWorkDuplConfirmYn: boolean;
	careIntervalWorkDuplConfirmYn: boolean;
	socialWorkerId: number;
	socialWorkerNm: string;
	syncCompleteDate: string;
	schedulePlanStartTime: string;
	schedulePlanEndTime: string;
	rfidStartTime: string;
	rfidEndTime: string;
	workStartTime: string;
	workEndTime: string;
}

export type SaveScheduleMonitoringDetailResponse = BaseResponse<SaveScheduleMonitoringDetailDTO>;

export interface UpdateWorkStatusRequest {
	centerId: number;
	serviceYm: string;
	recipientId: number;
	employeeId: number;
	serviceTypeCd: string;
	schedulePlanStartDate: string;
	schedulePlanEndDate: string;
}

export interface UpdateWorkStatusDTO {
	totCnt: number;
	successCnt: number;
	failureCnt: number;
	msg: string;
}

export type UpdateWorkStatusResponse = BaseResponse<UpdateWorkStatusDTO>;

export interface UpdateMonitoringDetailItemCompleteStatusRequest {
	centerId: number;
	serviceYm: string;
	recipientId: number;
	employeeId: number;
	serviceTypeCd: string;
	schedulePlanStartDate: string;
	schedulePlanEndDate: string;
	completeYn: boolean;
}

export interface UpdateMonitoringDetailItemCompleteStatusDTO {
	scheduleMonitoringChangeHistId: number;
	centerId: number;
	recipientId: number;
	employeeId: number;
	serviceTypeCd: string;
	serviceKindCd: string;
	serviceYm: string;
	serviceDt: string;
	schedulePlanStartDate: string;
	schedulePlanEndDate: string;
	schedulePlanSameDateSeq: number;
	recipientNm: string;
	recipientBirthDt: string;
	longTermNo: string;
	employeeNm: string;
	employeeBirthDt: string;
	providerNo: string;
	workStartDate: string;
	workEndDate: string;
	workConfirmYn: boolean;
	scheduleMonitoringMemo: string;
	otherCenterWorkDuplConfirmYn: boolean;
	otherCorpWorkDuplConfirmYn: boolean;
	careIntervalWorkDuplConfirmYn: boolean;
	stateChangeRecordingSheetExpectYn: boolean;
	specialHoursRecordingSheetExpectYn: boolean;
	cognitiveProgramRecordingSheetExpectYn: boolean;
	biweeklyBathRecordingSheetExpectYn: boolean;
	scheduleMonitoringCompleteYn: boolean;
}

export type UpdateMonitoringDetailItemCompleteStatusResponse =
	BaseResponse<UpdateMonitoringDetailItemCompleteStatusDTO>;

export interface UpdateMonitoringDetailsCompleteStatusRequest {
	centerId: number;
	serviceYm: string;
	serviceTypeCd: string;
	recipientId: number;
	employeeId1: number;
	employeeId2?: number;
	completeYn: boolean;
}

export type UpdateMonitoringDetailsCompleteStatusResponse = BaseResponse<number>;

export interface UpdateMonitoringCompleteStatusRequest {
	centerId: number;
	serviceYm: string;
	completeYn: boolean;
}

export type UpdateMonitoringCompleteStatusResponse = BaseResponse<number>;
