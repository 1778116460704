import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

interface BannerType {
	id: string;
	imageUrl: string;
	link: string;
	alt: string;
}

const DefaultBanners: BannerType[] = [
	{
		id: 'open_kiheung',
		imageUrl:
			'https://caring-static-public.s3.ap-northeast-2.amazonaws.com/images/banner_daycare_kiheung.png',
		link: 'https://blog.naver.com/cc_gg02/223229512309',
		alt: '용인 기흥점 오픈 이미지',
	},
	{
		id: 'open_yeonjae',
		imageUrl:
			'https://caring-static-public.s3.ap-northeast-2.amazonaws.com/images/banner_daycare_yeonjae.png',
		link: 'https://blog.naver.com/cc_bs05/223235682487',
		alt: '부산 연제점 오픈 이미지',
	},
	{
		id: 'open_kwonson',
		imageUrl:
			'https://caring-static-public.s3.ap-northeast-2.amazonaws.com/images/banner_daycare_kwonson.png',
		link: 'https://blog.naver.com/cc_gg01/223228841389',
		alt: '수원 권선점 오픈 이미지',
	},
];
interface Props {
	banners?: BannerType[];
}

function CRAdvertisingBanner({ banners = DefaultBanners }: Props) {
	return (
		<div style={{ padding: '1.6rem 2rem 1.6rem 2rem', background: 'white' }}>
			<Swiper
				modules={[Autoplay]}
				style={{ width: '100%', borderRadius: '8px', aspectRatio: 312 / 125 }}
				loop
				speed={2000}
				autoplay={{ delay: 3000, disableOnInteraction: false }}>
				{banners.map((banner) => (
					<SwiperSlide key={banner.id}>
						<img width='100%' src={banner.imageUrl} alt={banner.alt} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default CRAdvertisingBanner;
