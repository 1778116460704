import React from 'react';
import { displayPhoneNumber } from 'lib';
import CRRsdNumber from 'components/base/CRRsdNumber';
import { EmployeeBaseInfoDTO } from 'types/api/employee';
import dayjs from 'dayjs';
import InsuranceInfoColumn from 'components/ui/InsuranceInfoColumn/Index';
import * as S from './styles';

interface Props {
	item?: EmployeeBaseInfoDTO | null;
}

function PartTimeEmployeeDetailBasicInformationTable({ item }: Props): React.ReactElement {
	return (
		<S.Table>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>이름</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.korMemberNm}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>전화번호</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>
					{item?.mobilePhoneNo ? displayPhoneNumber(item?.mobilePhoneNo) : '-'}
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>담당자</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem', paddingRight: '5.3rem' }}>
					{item?.managerNm ?? '-'}
				</S.TableValueColumn>
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>주민등록번호</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>
					<CRRsdNumber rdsNumber={item?.rsdnNo} key={item?.rsdnNo} />
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>최근 건강 검진일</S.TableLabelColumn>
				<S.TableValueColumn
					style={{
						width: '24rem',
						paddingRight: '5.3rem',
					}}>
					{item?.healthCheckDate ? dayjs(item?.healthCheckDate).format('YYYY-MM-DD') : '-'}
				</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>4대보험</S.TableLabelColumn>
				<InsuranceInfoColumn {...item} />
			</S.TableRow>
			<S.TableRow>
				<S.TableLabelColumn style={{ width: '10.4rem' }}>주소</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.fullAddr || '-'}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '12rem' }}>직무</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>{item?.dutyNm ?? '-'}</S.TableValueColumn>
				<S.TableLabelColumn style={{ width: '16rem' }}>계약정보</S.TableLabelColumn>
				<S.TableValueColumn style={{ width: '24rem' }}>
					{item?.contractInfo ?? '-'}
				</S.TableValueColumn>
			</S.TableRow>
		</S.Table>
	);
}

export default PartTimeEmployeeDetailBasicInformationTable;
